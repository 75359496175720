import './App.css';

function App() {
  return (
    <div className="App">
      <img src={"https://cdn.discordapp.com/attachments/401896057111314442/978357825178857532/IMG20220519210922.jpg"} className="App-logo" alt="logo" />
      <br></br><br></br>
		  <h2>Recept:</h2>
		  <p>Ett glas prosecco, en piggelin. Lägg piggelinen i glaset och låt stå i 5 minuter.</p>
    </div>
  );
}

export default App;
